/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Mapa.module.sass";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import SearchIcon from "@mui/icons-material/Search";
// Leaflet
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  FeatureGroup,
  LayerGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "react-leaflet-draw";
import Control from "react-leaflet-custom-control";
import LeafletControlGeocoder from "../../const/LeafletControlGeocoder";
import RoomIcon from "@mui/icons-material/Room";

// Components
import Icon from "../../components/Icon";
import ModalSetting from "./ModalSetting";
import InfoCrearDraw from "./ModalSetting/InfoCrearDraw";
import InfoCrearNap from "./ModalSetting/InfoCrearNap";
import InfoEditMarker from "./ModalSetting/InfoEditMarker";
import InfoEditPolyline from "./ModalSetting/InfoEditPolyline";
import MapHook from "./MapHook";
import InfoSave from "./ModalSetting/InfoSave";
import ModalAlert from "../../components/ModalAlert";
import InfoAlert from "../../components/ModalAlert/InfoAlert";
import {
  openModalNap,
  renderSwitchColorNapReverse,
  renderSwitchColorReverse,
  renderSwitchIconsLeaflet,
} from "../../const/ConstMapa";
import { getDatasDrawsSave } from "../../const/ConstGlobal";
import guardar from "../../assets/icons/guardar.png";
import draggIcon from "../../assets/icons/all-directions.png";

// Axios
import {
  getOne,
  role,
  dashboard,
  user,
  update,
  permissionSecundario,
} from "./axios";
import groupBy from "lodash.groupby";
import SearchField from "../../const/LeafletControlGeocoder";
import MapPrint from "../../const/MapPrint";
import CommandControl from "./Command";

// Const
const { BaseLayer } = LayersControl;

var initialForm = {
  tituloMarcador: "Marker_Default",
  tituloRuta: "Polyline_Default",
  descripcionMarcador: "",
  descripcionRuta: "",
  colorTitulo: "default",
  colorTituloHex: "",
  colorRutaPopup: "default",
  colorRutaPopupHex: "",
  colorRuta: "#050dfa",
};

var initialFormNap = {
  napNumeroHilo: 0,
  napColorHilo: "",
  napColorHilohex: "",
  napNumeroBuffer: 0,
  napColorBuffer: "",
  napColorBufferhex: "",
  napNumeroPuertos: 0,
};

var initialMarkerLeaflet = {
  icon: renderSwitchIconsLeaflet("HiLocationMarker"),
  nameIcon: "HiLocationMarker",
  repeatMode: true,
};

var modalData = {
  isOpen: false,
  iconName: "confetti",
  iconEmoji: "🚧",
  title: "Obligatorio",
  subtitle: "",
  message:
    "Por favor necesitas crear marcador o ruta en el mapa para poder guardar.",
  buttonCancel: "Aceptar",
};

const MapaEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [markerLeaflet, setMarkerLeaflet] = useState({
    icon: renderSwitchIconsLeaflet("HiLocationMarker"),
    nameIcon: "HiLocationMarker",
    repeatMode: true,
  });
  const [polylineLeaflet, setPolylineLeaflet] = useState({
    repeatMode: true,
    icon: new L.Icon.Default(),
    shapeOptions: {
      guidelineDistance: 1,
      color: "blue",
      weight: 5,
    },
  });
  const [drawCreatedForm, setDrawCreatedForm] = useState({
    tituloMarcador: "Marker_Default",
    tituloRuta: "Polyline_Default",
    descripcionMarcador: "",
    descripcionRuta: "",
    colorTitulo: "default",
    colorTituloHex: "",
    colorRutaPopup: "default",
    colorRutaPopupHex: "",
    colorRuta: "#050dfa",
  });
  const [drawEditForm, setDrawEditForm] = useState({
    tituloMarcador: "Marker_Default",
    tituloRuta: "Polyline_Default",
    descripcionMarcador: "",
    descripcionRuta: "",
    colorTitulo: "default",
    colorTituloHex: "",
    colorRutaPopup: "default",
    colorRutaPopupHex: "",
    colorRuta: "#050dfa",
    coords: {},
    iconMarker: "",
    totalDistance: "",
    type: "",
    napNumeroHilo: 0,
    napColorHilo: "",
    napColorHilohex: "",
    napNumeroBuffer: 0,
    napColorBuffer: "",
    napColorBufferhex: "",
    napNumeroPuertos: 0,
  });
  const [drawCreatedFormNap, setDrawCreatedFormNap] = useState({
    napNumeroHilo: 0,
    napColorHilo: "",
    napColorHilohex: "",
    napNumeroBuffer: 0,
    napColorBuffer: "",
    napColorBufferhex: "",
    napNumeroPuertos: 0,
  });

  const [layerEdit, setLayerEdit] = useState({});
  // const [reloaderMap, setReloaderMap] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalAlert, setVisibleModalAlert] = useState(false);
  // const [reloadSave, setReloadSave] = useState(false);
  const [visibleModalType, setVisibleModalType] = useState("");
  const [datasDrawsTotal, setDatasDrawsTotal] = useState({
    marcadoresCount: 0,
    rutasCount: 0,
    mangasCount: 0,
    reservasCount: 0,
    nap_iCount: 0,
    nap_iiCount: 0,
    ontCount: 0,
    sumaDistanceRutas: 0,
  });
  const [tituloFicha, setTituloFicha] = useState("");
  const [markersSave, setMarkersSave] = useState([]);
  const [polylinesSave, setPolylinesSave] = useState([]);
  const [getMarkers, setGetMarkers] = useState([]);


  const [editMarker, setEditMarker] = useState({});
  const [isRutasChecked, setIsRutasChecked] = useState(true);

  const [isDraggable, setIsDraggable] = useState(false);

  const [getPolylines, setGetPolylines] = useState([]);
  const [reload, setReload] = useState(false);

  const [viewport, setViewport] = useState({
    center: [-0.2304206285074057, -78.183406],
    zoom: 9,
  });

  useEffect(() => {
    loadDrawInfo();
  }, []);

  const [showRoutes, setShowRoutes] = useState(true);
  // const toggleRoutes = () => setShowRoutes(!showRoutes);

  const loadDrawInfo = async () => {
    try {
      let apiRoleReturns = await role();
      if (apiRoleReturns.data === "SECUNDARIO") {
        let apiPermissionReturns = await permissionSecundario();
        if (apiPermissionReturns.data) {
          if (!apiPermissionReturns.data.includes("EDIT-MAP")) setReload(true);
        }
        if (apiPermissionReturns.data === "") setReload(true);
      }

      let apiMapReturns = await getOne(id);
      if (
        apiRoleReturns.data === "ADMIN" ||
        apiRoleReturns.data === "SECUNDARIO"
      ) {
        let apiinfoReturns = await dashboard();
        let apiuserReturns = await user();
        if (
          apiinfoReturns.data[0].mangas >= apiuserReturns.data.Mangas ||
          apiinfoReturns.data[0].marcadores >= apiuserReturns.data.Marcadores ||
          apiinfoReturns.data[0].nap_i >= apiuserReturns.data.NAP_I ||
          apiinfoReturns.data[0].nap_ii >= apiuserReturns.data.NAP_II ||
          apiinfoReturns.data[0].ont >= apiuserReturns.data.ONTS ||
          apiinfoReturns.data[0].reservas >= apiuserReturns.data.Reservas ||
          apiinfoReturns.data[0].rutas >= apiuserReturns.data.Rutas
        ) {
          modalData = {
            isOpen: false,
            iconName: "confetti",
            iconEmoji: "⚠️",
            title: "Limites!!!",
            subtitle: "",
            message:
              "Ya llegaste al limite de Marcadores, contacta para conseguir un Plan sin limites!",
            buttonCancel: "Aceptar",
          };
          setVisibleModalAlert(true);

          setTimeout(() => {
            setVisibleModalAlert(false);
          }, 3000);
          setTimeout(() => {
            navigate("/fichas");
          }, 3500);
        }
      }

      setGetMarkers(apiMapReturns.data.data.markers);
      setGetPolylines(apiMapReturns.data.data.polylines);
      setTituloFicha(apiMapReturns.data.data.titulo);
    } catch (error) {
      console.error(error);
    }
  };

  // Para marker seleccionable
  const handlerSelectMarker = (item) => {
    initialMarkerLeaflet["nameIcon"] = item;
    initialMarkerLeaflet["icon"] = renderSwitchIconsLeaflet(item);
    setMarkerLeaflet((prev) => ({
      ...prev,
      nameIcon: item,
      icon: renderSwitchIconsLeaflet(item),
    }));
    setVisibleModal(false);
    if (openModalNap.includes(item)) {
      setVisibleModal(true);
      setVisibleModalType(4);
    }
  };

  // Para polyline seleccionable
  const handlerSelectColorPolyline = (value) => {
    let newDrawOptionsPolyline = {
      repeatMode: true,
      icon: new L.Icon.Default(),
      shapeOptions: {
        guidelineDistance: 1,
        color: value,
        weight: 5,
      },
    };
    setPolylineLeaflet(newDrawOptionsPolyline);
  };

  // Para ocultar los dibujados
  // const reloaderMapInfo = () => {
  //   setReloaderMap((prev) => !prev);
  // };

  // Para sacar calculos de marker y polyline
  const handlerTotalDraw = () => {

    let countMarcadores = 0;
    let countManga = 0;
    let countReserva = 0;
    let countNap_i = 0;
    let countNap_ii = 0;
    let countOnt = 0;
    let countRutas = 0;
    let sumaRutasDistance = 0;
    let markersArray = getMarkers;
    let polylinesArray = [];



    markersArray.map((item) => {
      if (item && item.iconName || item && item.
        iconMarker) {
        if (item.iconName === "FcWorkflow" || item.iconMarker=="FcWorkflow") {
          countManga++;
        } else if (item.iconName === "FcTimeline" || item.iconMarker=="FcTimeline") {
          countReserva++;
        } else if (item.iconName === "FcFilingCabinet" || item.iconMarker=="FcFilingCabinet") {
          countNap_i++;
        } else if (item.iconName === "GrServers" || item.iconMarker=="GrServers") {
          countNap_ii++;
        } else if (item.iconName === "FcReadingEbook" || item.iconMarker=="FcReadingEbook") {
          countOnt++;
        } else {
          countMarcadores++;
        }
      } else {

        console.error("Item or item.iconName is null or undefined:", item);
      }
    });



    getDatasDrawsSave() &&
      getDatasDrawsSave().map((item) => {
        if (item.myTag.type === "polyline") {
          if (item.myTag.totalDistance !== 0) {
            sumaRutasDistance += item.myTag.totalDistance;
            countRutas += 1;
            let obj = {
              ...item.myTag,
              categoryType: "Rutas",
            };
            polylinesArray.push(obj);
          }
        }
      });

    setDatasDrawsTotal((prev) => ({
      ...prev,
      marcadoresCount: countMarcadores,
      mangasCount: countManga,
      reservasCount: countReserva,
      nap_iCount: countNap_i,
      nap_iiCount: countNap_ii,
      ontCount: countOnt,
      rutasCount: countRutas,
      sumaDistanceRutas: sumaRutasDistance,
    }));
    setMarkersSave(markersArray);
    setPolylinesSave(polylinesArray);
  };

  // Mostrar mensaje vacio de draw del boton save
  const handlerMessageDraw = () => {
    modalData = {
      isOpen: false,
      iconName: "confetti",
      iconEmoji: "🚧",
      title: "Obligatorio",
      subtitle: "",
      message:
        "Por favor necesitas crear marcador o ruta en el mapa para poder guardar.",
      buttonCancel: "Aceptar",
    };
    setVisibleModalAlert(true);
  };

  // Para guardar al backend
  const onHandlerSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      let dataUpdate = await update(
        id,
        tituloFicha,
        datasDrawsTotal,
        markersSave,
        polylinesSave
      );

      if (dataUpdate.status === "UPDATED") {
        modalData = {
          isOpen: false,
          iconName: "confetti",
          iconEmoji: "✅",
          title: "Éxito Actualización",
          subtitle: "",
          message: `Se ha actualizado la ficha.`,
          buttonCancel: "Aceptar",
        };
        setVisibleModalAlert(true);
        setTimeout(() => {
          setVisibleModalAlert(false);
        }, 3000);
        setTimeout(() => {
          navigate("/fichas");
        }, 3500);
      } else {
        modalData = {
          isOpen: false,
          iconName: "confetti",
          iconEmoji: "❌",
          title: "Error de actualizar",
          subtitle: "",
          message: `${dataUpdate.msg}`,
          buttonCancel: "Aceptar",
        };
        setVisibleModalAlert(true);
      }

      setVisibleModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const settingModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setVisibleModal(true);
    setVisibleModalType(0);
  };

  const addModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setVisibleModal(true);
    setVisibleModalType(3);
    handlerTotalDraw();
    // if (getDatasDrawsSave().length) {
    //   setVisibleModal(true);
    //   setVisibleModalType(3);
    //   handlerTotalDraw();
    // } else {
    //   handlerMessageDraw();
    // }
  };
  const CATEGORY_TYPES = [
    "Manga",
    "Reserva",
    "Nap_I",
    "Nap_II",
    "Marcadores",
    "Ont",
  ];

  const [groupedByType, setMarkerGroupBy] = useState(null);
  const [addMark, setAddMark] = useState(false);
  const [searchFieldVisible, setSearchFieldVisible] = useState(false);
  // addzoom only on load
  const [addZoomOnlyOnLoad, setAddZoomOnlyOnLoad] = useState(true);
  const [bounds, setBounds] = useState("");

  useEffect(() => {
    const groupedByType = groupBy(
      getMarkers,
      (getMarkers) => getMarkers?.categoryType
    );

    setMarkerGroupBy(groupedByType);

    let coordinatesFound = false;
    if (addZoomOnlyOnLoad) {
      for (const type in groupedByType) {
        if (groupedByType.hasOwnProperty(type)) {
          // Check if the type has data
          if (groupedByType[type].length > 0) {
            // Loop through each item in the type
            for (const item of groupedByType[type]) {
              // Check if the item has Coords property and it has non-zero data
              if (
                item.Coords &&
                item.Coords.lat !== 0 &&
                item.Coords.lng !== 0
              ) {
                // Retrieve the coordinates dynamically
                const lat = item.Coords.lat;
                const lng = item.Coords.lng;

                let coord = [lat, lng];

                setBounds((prevBounds) =>
                  prevBounds
                    ? prevBounds.extend(item.Coords)
                    : L.latLngBounds(item.Coords, item.Coords)
                );

                setViewport({ ...viewport, center: coord });
                coordinatesFound = true;
              }
            }
          }
          if (coordinatesFound) {
            setAddZoomOnlyOnLoad(false);
            break;
          }
        }
      }
    }
  }, [getMarkers]);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setIsRutasChecked(true);
      setShowRoutes(true);
    } else {
      setIsRutasChecked(false);
      setShowRoutes(false);
    }
  };

  if (reload) return <Navigate to={"/"} />;

  const handleIconClick = () => {
    setAddMark(!addMark);
  };

  const toggleSearchField = () => {
    setSearchFieldVisible(!searchFieldVisible);
  };
  // let bounds = null;

  return (
    <>

      <MapContainer
        key={viewport.center}
        center={viewport.center}
        zoom={viewport.zoom}
        style={{
          position: "relative",
          zIndex: "0",
          height: "calc(100vh - 250px)",
          borderRadius: "20px",
          boxShadow: " 5px 5px 15px rgba(0,0,0,0.4)",
        }}
        bounceAtZoomLimits={true}
        // maxBoundsViscosity={0.95}
        // maxBounds={[
        //   [-85, -180],
        //   [85, 180],
        // ]}
        bounds={bounds}
      >
        <MapPrint
          position="topleft"
          sizeModes={["Current", "A4Portrait", "A4Landscape"]}
          hideControlContainer={false}
          title="Print"
        />

        <MapPrint
          position="topleft"
          sizeModes={["A4Portrait", "A4Landscape"]}
          hideControlContainer={false}
          title="Exportar as PNG"
          exportOnly
        />

        <SearchField setSearchFieldVisible={setSearchFieldVisible} searchFieldVisible={searchFieldVisible} />

        <LayersControl position="topleft" style={{ position: "relative" }}>
          {CATEGORY_TYPES?.map((type, index) => {
            return (
              <LayersControl.Overlay key={index} name={type} checked>
                <LayerGroup>
                  {groupedByType &&
                    groupedByType[type]?.map((item, index) => {
                      return (
                        <Marker
                          key={index}
                          position={item.Coords}
                          icon={renderSwitchIconsLeaflet(
                            item.iconName ? item.iconName : item.iconMarker
                          )}
                          draggable={isDraggable && true}
                          eventHandlers={{
                            dblclick: (e) => {
                              setVisibleModal(true);
                              setVisibleModalType(1);

                              setEditMarker((prevState) => ({
                                id: item.id,
                                titulo: item.titulo,
                                descripcion: item.descripcion,
                                iconName: item.iconName,
                                colorTitulo: item.colorTitulo,
                                colorTituloHex: renderSwitchColorReverse(
                                  item.colorTitulo
                                ),
                                Coords: item.Coords,
                                type: item.type,
                                iconMarker: item.iconMarker,
                                napNumeroHilo: item.napNumeroHilo
                                  ? item.napNumeroHilo
                                  : item.numeroHilo
                                  ? item.numeroHilo
                                  : 0,
                                napColorHilo: item.colorHilo
                                  ? item.colorHilo
                                  : "",
                                napColorHilohex: item.colorHilo
                                  ? renderSwitchColorNapReverse(item.colorHilo)
                                  : "",
                                napNumeroBuffer: item.napNumeroBuffer
                                  ? item.napNumeroBuffer
                                  : item.numeroBuffer
                                  ? item.numeroBuffer
                                  : 0,
                                napColorBuffer: item.napColorBuffer
                                  ? item.napColorBuffer
                                  : item.colorBuffer
                                  ? item.colorBuffer
                                  : "",
                                napColorBufferhex: item.colorBuffer
                                  ? renderSwitchColorNapReverse(
                                      item.colorBuffer
                                    )
                                  : "",
                                napNumeroPuertos: item.napNumeroPuertos
                                  ? item.napNumeroPuertos
                                  : item.numeroPuertos
                                  ? item.numeroPuertos
                                  : 0,
                              }));
                              console.log("On marker double click check item", item);
                              setLayerEdit(item);
                            },
                            dragend: (e) => {
                              const newPosition = e.target.getLatLng();

                              const markerIndex = item.id;

                              const newMarkerArr = [...getMarkers];

                              let markers = {
                                ...item,
                                Coords: {
                                  lat: newPosition.lat,
                                  lng: newPosition.lng,
                                },
                              };

                              newMarkerArr[markerIndex] = {
                                ...newMarkerArr[markerIndex],
                                ...markers,
                              };

                              setGetMarkers(newMarkerArr);
                            },
                          }}
                        >
                          <Popup>
                            Título:{item.titulo}
                            <br />
                            Descripción:{item.descripcion}
                            <br />
                            <br />
                            Coordinates
                            <br />
                            Latitude {item?.Coords?.lat} <br />
                            Longitude {item?.Coords?.lng}
                            <br />
                            <br />
                            {item.iconName === "FcFilingCabinet" && (
                              <>
                                Hilos <br />
                                Color:{" "}
                                {item.napColorHilo
                                  ? item.napColorHilo
                                  : item.colorHilo
                                  ? item.colorHilo
                                  : "Default Color"}{" "}
                                <br />
                                Número :
                                {item.napNumeroHilo
                                  ? item.napNumeroHilo
                                  : item.numeroHilo
                                  ? item.numeroHilo
                                  : 0}
                                <br />
                                <br />
                                Buffer:{" "}
                                {item.napColorBuffer
                                  ? item.napColorBuffer
                                  : item.colorBuffer
                                  ? item.colorBuffer
                                  : "Default Color"}{" "}
                                <br />
                                Número :
                                {item.napNumeroBuffer
                                  ? item.napNumeroBuffer
                                  : item.numeroBuffer
                                  ? item.numeroBuffer
                                  : 0}
                                <br />
                                <br />
                                Puertos :
                                {item.napNumeroPuertos
                                  ? item.napNumeroPuertos
                                  : item.numeroPuertos
                                  ? item.numeroPuertos
                                  : 0}
                              </>
                            )}
                            {item.iconName == "GrServers" && (
                              <>
                                Hilos <br />
                                Color:{" "}
                                {item.napColorHilo
                                  ? item.napColorHilo
                                  : item.colorHilo
                                  ? item.colorHilo
                                  : "Default Color"}{" "}
                                <br />
                                Número :
                                {item.napNumeroHilo
                                  ? item.napNumeroHilo
                                  : item.numeroHilo
                                  ? item.numeroHilo
                                  : 0}
                                <br />
                                <br />
                                Buffer:{" "}
                                {item.napColorBuffer
                                  ? item.napColorBuffer
                                  : item.colorBuffer
                                  ? item.colorBuffer
                                  : "Default Color"}{" "}
                                <br />
                                Número :
                                {item.napNumeroBuffer
                                  ? item.napNumeroBuffer
                                  : item.numeroBuffer
                                  ? item.numeroBuffer
                                  : 0}
                                <br />
                                <br />
                                Puertos :
                                {item.napNumeroPuertos
                                  ? item.napNumeroPuertos
                                  : item.numeroPuertos
                                  ? item.numeroPuertos
                                  : 0}
                              </>
                            )}
                          </Popup>
                        </Marker>
                      );
                    })}
                </LayerGroup>
              </LayersControl.Overlay>
            );
          })}

          <BaseLayer name="Clean Mode">
            <TileLayer
              noWrap={true}
              url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="OpenStreet">
            <TileLayer
              noWrap={true}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer checked name="Base Mapa">
            <TileLayer
              noWrap={true}
              url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              // maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
            />
          </BaseLayer>
          <BaseLayer name="Satelite Mode">
            <TileLayer
              noWrap={true}
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              // maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
            />
          </BaseLayer>

          <BaseLayer>
            {/* <Control prepend position="topright" style={{ borderWidth: "0" }}>
              <button
                className={cn("button-square-stroke button-small", styles.head)}
                onClick={() => reloaderMapInfo()}
              >
                <Icon
                  name={reloaderMap ? "toggle-off-stroke" : "toggle-on"}
                  size="24"
                />
              </button>
            </Control> */}

            <Control prepend position="topright" style={{ borderWidth: "0" }}>
              <Tooltip
                arrow
                placement="right"
                title={addMark ? "" : "Add Marker"}
              >
                <div
                  className={cn(
                    "button-square-stroke button-small",
                    styles.head
                  )}
                >
                  <div
                    className="flex"
                    style={{ flexDirection: "row", position: "relative" }}
                  >
                    <div
                      style={{
                        display: addMark ? "block" : "none",
                        position: "absolute",
                        left: "-5.8rem",
                        background: "#A0A098",
                        height: "36px",
                        top: "-6px",
                        width: "5rem",
                        borderRadius: "8px",
                        // Add other styles for the "Cancel" text if needed
                      }}
                    >
                      <button
                        style={{
                          marginTop: "10px",
                          color: "white",
                        }}
                        onClick={handleIconClick}
                      >
                        Cancel
                      </button>
                    </div>
                    <div onClick={handleIconClick}>
                      <RoomIcon size="24" />
                    </div>
                  </div>
                </div>
              </Tooltip>
            </Control>
            <Control prepend position="topright" style={{ borderWidth: "0" }}>
              <div
                className={cn("button-square-stroke button-small", styles.head)}
              >
                <div
                  className="flex"
                  style={{ flexDirection: "row", position: "relative" }}
                >
                  <div
                    style={{
                      display: isDraggable ? "block" : "none",
                      position: "absolute",
                      left: "-5.8rem",
                      background: "#A0A098",
                      height: "36px",
                      top: "0px",
                      width: "5rem",
                      borderRadius: "8px",
                    }}
                  >
                    <button
                      style={{
                        marginTop: "10px",
                        color: "white",
                      }}
                      onClick={() => {
                        setIsDraggable(!isDraggable);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setIsDraggable(!isDraggable);
                    }}
                  >
                    {/* <RoomIcon size="24" /> */}
                    <img
                      src={draggIcon}
                      style={{
                        color: "#6F767E !important",
                        width: "35px",
                        height: "35px",
                        padding: "0.4rem",
                        borderRadius: "2px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Control>
            <Control prepend position="topright" style={{ borderWidth: "0" }}>
              <button
                className={cn("  ", styles.head)}
                style={{
                  width: "40px",
                  height: "40px",
                  background: "#f5f5f5",
                  borderRadius: "8px",
                }}
                onClick={toggleSearchField}
              >
                <SearchIcon
                  className="searchIcon"
                  color="#F4F4F4"
                  fontSize={"small"}
                  style={{
                    color: "#6F767E !important",
                    width: "35px",
                    height: "35px",
                    padding: "0.4rem",
                    borderRadius: "2px",
                  }}
                />
              </button>
            </Control>
            <Control prepend position="topright" style={{ borderWidth: "0" }}>
              <Tooltip arrow placement="right" title="Actualizar Ficha">
                <button
                  className={cn(
                    "button-square-stroke button-small",
                    styles.head
                  )}
                  onClick={addModal}
                >
                  <img src={guardar} height="24" width="24" alt="Guardar" />
                </button>
              </Tooltip>
            </Control>

            <Control prepend position="topright" style={{ borderWidth: "0" }}>
              <Tooltip arrow placement="right" title="Configuración">
                <button
                  className={cn(
                    "button-square-stroke button-small",
                    styles.head
                  )}
                  onClick={settingModal}
                >
                  <Icon name="setting" size="24" />
                </button>
              </Tooltip>
            </Control>
          </BaseLayer>
          <FeatureGroup>
            {getMarkers.length || getPolylines.length ? (

                <MapHook
                  dataMarkers={getMarkers}
                  dataPolylines={getPolylines}
                  markerLeaflet={markerLeaflet}
                  polylineLeaflet={polylineLeaflet}
                  initialMarkerLeaflet={initialMarkerLeaflet}
                  initialForm={initialForm}
                  initialFormNap={initialFormNap}
                  setDrawEditForm={setDrawEditForm}
                  setLayerEdit={setLayerEdit}
                  onEditVisibleModalMarker={() => [
                    setVisibleModal(true),
                    setVisibleModalType(1),
                  ]}
                  onEditVisibleModalPolyline={() => [
                    setVisibleModal(true),
                    setVisibleModalType(2),
                  ]}
                  onClose={() => setVisibleModal(false)}
                  getMarkers={getMarkers}
                  setGetMarkers={setGetMarkers}
                  addMark={addMark}
                  showRoutes={showRoutes}
                  bounds={bounds}
                />
            ) : null}
          </FeatureGroup>
          <Control
            prepend
            style={{
              position: "absolute",
              left: 0,
            }}
          >
            <div
            // style={{
            //   position: "absolute",
            //   zIndex: 555,
            //   top: "270px",
            //   left: "0",
            //   background: "white",
            //   padding: "0.2rem",
            //   cursor: "pointer",
            //   borderRadius: "10px",
            // }}
            >
              <Checkbox
                checked={isRutasChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsRutasChecked(true);
                    setShowRoutes(true);
                  } else {
                    setIsRutasChecked(false);
                    setShowRoutes(false);
                  }
                }}
              />
            </div>
          </Control>
        </LayersControl>
        <CommandControl
          setIsRutasChecked={setIsRutasChecked}
          setShowRoutes={setShowRoutes}
        />

        {/* <Control
          prepend
          style={{position}}
          position="topleft"
        >
          <div
            // style={{
            //   position: "absolute",
            //   zIndex: 555,
            //   top: "230px",
            //   left: "0",
            //   background: "white",
            //   padding: "0.2rem",
            //   cursor: "pointer",
            //   borderRadius: "10px",

            // }}
          >
            <Checkbox
              checked={isRutasChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsRutasChecked(true);
                  setShowRoutes(true);
                } else {
                  setIsRutasChecked(false);
                  setShowRoutes(false);
                }
              }}
            />
          </div> */}
        {/* </Control> */}
      </MapContainer>
      <ModalSetting
        outerClassName={
          visibleModalType === 0 ? styles.outer : styles.outerEditMarker
        }
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        {visibleModalType === 0 ? (
          <InfoCrearDraw
            drawCreatedForm={drawCreatedForm}
            setDrawCreatedForm={setDrawCreatedForm}
            initialForm={initialForm}
            handlerSelectMarker={handlerSelectMarker}
            handlerSelectColorPolyline={handlerSelectColorPolyline}
            markerLeaflet={markerLeaflet}
          />
        ) : visibleModalType === 1 ? (
          <InfoEditMarker
            drawEditForm={drawEditForm}
            setDrawEditForm={setDrawEditForm}
            layerEdit={layerEdit}
            onClose={() => setVisibleModal(false)}
            editMarker={editMarker}
            setEditMarker={setEditMarker}
            getMarkers={getMarkers}
            setGetMarkers={setGetMarkers}
          />
        ) : visibleModalType === 2 ? (
          <InfoEditPolyline
            drawEditForm={drawEditForm}
            setDrawEditForm={setDrawEditForm}
            layerEdit={layerEdit}
            onClose={() => setVisibleModal(false)}
          />
        ) : visibleModalType === 3 ? (
          <InfoSave
            datasDrawsTotal={datasDrawsTotal}
            setTituloFicha={setTituloFicha}
            tituloFicha={tituloFicha}
            onHandlerSave={onHandlerSave}
          />
        ) : visibleModalType === 4 ? (
          <InfoCrearNap
            drawCreatedFormNap={drawCreatedFormNap}
            setDrawCreatedFormNap={setDrawCreatedFormNap}
            initialFormNap={initialFormNap}
            onClose={() => setVisibleModal(false)}
          />
        ) : null}
      </ModalSetting>
      <ModalAlert
        outerClassName={styles.outer}
        visible={visibleModalAlert}
        onClose={() => setVisibleModalAlert(false)}
      >
        <InfoAlert
          modalData={modalData}
          onClose={() => setVisibleModalAlert(false)}
        />
      </ModalAlert>
    </>
  );
};

export default MapaEdit;
